import React, { useContext } from 'react';
import { useDeviceContext } from 'UserDevice'

import css from "./infoset.module.css";

export const SPECS = {
    mp: 'Специальность'
    , hb: 'Направление подготовки'
    , hs: 'Специальность'
    , hm: 'Направление подготовки'
    , hha: 'Направление подготовки'
    , hhd: 'Направление подготовки'
    , hho: 'Специальность'
    , hhs: 'Специальность'
    , ap: 'Наименование программы'
    , p: 'Наименование программы профессионального обучения'
};

export function Infoset(props){
	let mobile = useDeviceContext();
    var main_info_block = [];
    var main_info_no_block = [];
    var main_info = [];
    var subname = null;
    var extinfo = false;
    var infoset = props.value;
    var dtype = props.dtype;
    var in_group = false;
    var first_head = null;
    var table_cols = 2;

    for(var i = 0; i < infoset.length; i++){
        var k = infoset[i][0];
        var v = infoset[i][1];
        var type = "value";
        var vals = v;

        if(subname){
            subname = null;
            continue;
        }
        if(k.toUpperCase().startsWith('ДОПОЛНИТЕЛЬНЫЕ СВЕДЕНИЯ')){
            extinfo = true;
        }

        if(typeof v === 'object' && v !== null){
            if(v.group){
                subname = infoset[i+1] && !infoset[i+1][1].length ? infoset[i+1][0] : null;
                type = subname ? "value" : "header_no_subname";
                first_head = (first_head === null) ? true : false;
                in_group = true;
            }
            if(v.th){
                type = "header";
                first_head = (first_head === null) ? true : false;
                in_group = false;
            }
            vals = v.columns??(Array.isArray(v)?v:null);
            table_cols = Array.isArray(vals) && vals.length+1 > table_cols ? vals.length+1 : table_cols;
        };

        var mi = {
          type: type
          , ext: extinfo
          , name: k
          , subname: subname
          , value: vals
          , linestyle: (subname || type === "header" || type === "header_no_subname") && !first_head ?
                css.line+' '+css[dtype] : null
          , tdstyle: in_group ?
                (subname || type === "header_no_subname") ? css.inGroup+' '+css.head
                : css.inGroup
             : null
        };

        if(first_head === null){
            main_info_no_block.push(mi)
        }else{
            main_info_block.push(mi)
        }

        main_info = [main_info_block];
        if(main_info_no_block.length !==0)
            main_info.unshift(main_info_no_block);
    }

    return (
            main_info.map((mi, i) =>
                <table className={mobile} key={i}>
                <tbody>
                {
                    mi.map((m, j) =>
                        <>
                        {
                            m.linestyle ?
                            <tr key={m.linestyle}>
                                <th colSpan={table_cols}><hr className={m.linestyle} /></th>
                            </tr>
                            : null
                        }
                        {
                            m.type === 'header' ?
                            <tr key={m.name}>
                                <th className={css.thHeader} colSpan={table_cols}>{m.name}</th>
                            </tr>
                            : null
                        }
                        <tr key={`${i}`+`${j}`}>
                            {
                                m.type === 'header' ?
                                    m.value ? m.value.map(v =>
                                        <th key={v}>{v}</th>
                                    ): null :
                                    (<>
                                    <td className={m.tdstyle} key={m.name}>{m.name}
                                        {m.subname ?
                                            <>
                                                <br/>
                                                <small>{m.subname}</small>
                                            </>
                                            : null
                                        }
                                    </td>
                                        {
                                            Array.isArray(m.value) ? m.value.map(v =>
                                                    <td className={m.tdstyle} key={v}>{v}</td>
                                                )
                                            :
                                                <td className={m.tdstyle}>{ m.value }</td>
                                        }
                                    </>
                                    )
                            }
                        </tr>
                        </>
                    )
                }
                </tbody>
                </table>
            )
    )
}

export function Orginfo(props){
    var orginfo = props.value;

    return(
        <>
        <div className="infoBlock">
            <p>Наименование организации</p>
            <h4>{orginfo.name}</h4>
        </div>
        {
            !props.mobile?
            <table>
            <tbody>
                <tr>
                    <td>
                        <div className="infoBlock">
                            <p>Место нахождения организации</p>
                            <h4>{orginfo.app_place}</h4>
                        </div>
                    </td>
                    <td>
                        <div className="infoBlock">
                            <p>ОГРН организации</p>
                            <h4>{orginfo.gov_regnum}</h4>
                        </div>
                    </td>
                </tr>
            </tbody>
            </table>
            :
            <div className="flexContainer">
                <div className="infoBlock">
                    <p>Место нахождения организации</p>
                    <h4>{orginfo.app_place}</h4>
                </div>
                <div className="infoBlock">
                    <p>ОГРН организации</p>
                    <h4>{orginfo.gov_regnum}</h4>
                </div>
            </div>
        }
        </>
    )
}

export function Spec({dtype, spec, mobile, ...props}){
    console.log(spec)
    return spec.map((s, i) => <>
        <hr className={css.line+' '+css[dtype]} />
        <h2>{SPECS[dtype]} {spec.length > 1 ? i+1 : null}</h2>
        {
            !mobile?
                <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="infoBlock">
                                <p>Наименование</p>
                                <h4>{s.spec_name}</h4>
                            </div>
                        </td>
                        {s.qualification && <td>
                            <div className="infoBlock">
                                <p>Квалификация</p>
                                <h4>{s.qualification}</h4>
                            </div>
                        </td>
                        }
                        {s.spec_code && <td>
                            <div className="infoBlock">
                                <p>Код</p>
                                <h4>{s.spec_code}</h4>
                            </div>
                        </td>
                        }
                    </tr>
                    <tr>
                        {s.qualification_category && <td>
                            <div className="infoBlock">
                                <p>Квалификационный разряд, класс, категория</p>
                                <h4>{s.qualification_category}</h4>
                            </div>
                        </td>
                        }
                        {s.area_proffessional_program && <td>
                            <div className="infoBlock">
                                <p>Наименование профессий рабочих, должностей служащих</p>
                                <h4>{s.area_proffessional_program}</h4>
                            </div>
                        </td>
                        }
                    </tr>
                </tbody>
                </table>
            :
                <div className="flexContainer">
                    <div className="infoBlock">
                        <p>Наименование</p>
                        <h4>{s.spec_name}</h4>
                    </div>
                    <div className="infoBlock">
                        <p>Квалификация</p>
                        <h4>{s.qualification}</h4>
                    </div>
                    <div className="infoBlock">
                        <p>Код</p>
                        <h4>{s.spec_code}</h4>
                    </div>
                </div>
        }
        </>
    )
}

export function Signers(props){
    var sign = props.value;

    return (
    <>
        <h2>Сведения о подписавших цифровой документ</h2>
        <div className="flexContainer">
            {sign && sign.length ? sign.map((s, i)=>
                <div className="infoBlock" key={i}>
                    <p>{s.r}</p>
                    <h4>{s.i[1]+' '+s.i[2]+' '+s.i[3]}</h4>
                </div>)
                : <h4>-нет-</h4>
            }
        </div>
    </>
    );
}